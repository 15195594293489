body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rainbow-text {
  background: linear-gradient(90deg, #4c81f2, #a670bd, #a670bd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.white-text {
  color: white;
}

.rainbow-button {
  background: linear-gradient(90deg, #4c81f2, #a670bd, #a670bd);
}
