.rainbow-text {
    background: linear-gradient(90deg, #4c81f2, #a670bd, #a670bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}

.white-text {
    color: white;
}

.rainbow-button {
    background: linear-gradient(90deg, #4c81f2, #a670bd, #a670bd);
}
